<template>
  <v-container fluid>
    <v-row class="nav-bar">
      <router-link :to="{ name: 'Home' }">home</router-link>
      <span v-if="!$vuetify.breakpoint.mobile">&emsp;/&emsp;</span><span v-else>&nbsp;/&nbsp;</span>
      <router-link :to="{ name: 'About' }">about</router-link>
      <span v-if="!$vuetify.breakpoint.mobile">&emsp;/&emsp;</span><span v-else>&nbsp;/&nbsp;</span>
      <router-link :to="{ name: 'Archive' }">archive</router-link>
      <v-spacer />
      <v-spacer />
    </v-row>
  </v-container>
</template>
<script>
  export default {
    name: 'NavBar',
  };
</script>
<style>
.nav-bar, .nav-bar a {
  font-size: 2rem;
  font-family: LunaSans;
}
.nav-bar {
  user-select: none;
}
</style>
