<template>
  <v-card class="card">
    <v-card-title primary-title>
      <p class="dialogTitle">Submission Guidelines</p>
      <div class="bar"></div>
    </v-card-title>
    <v-card-text class="dialogText">
      <p>
        <ul>
          <li>Please submit four shorter poems (40 lines or less) or a single piece or extract of up to 150 lines.</li>
          <li>Text file must be editable, e.g. .doc, .docx, .txt, .odt etc.</li>
          <li>Please do not include your name anywhere in the document or the filename.</li>
          <li>Our submission window will close on 2<sup>nd</sup> February at midnight.</li>
          <li>We aim to contact all poets with a decision by the 2<sup>nd</sup> March 2022.</li>
          <li>We welcome simultaneous submissions. Please notify us immediately if your work is accepted elsewhere.</li>
          <li>If you haven’t already, we encourage prospective contributors to familiarise themselves with the types of poetry we publish by purchasing a copy of a previous issue, (see below!).</li>
          <li>We encourage and actively seek work by poets from underrepresented backgrounds, including BAME, LGBTQ+, neurodivergent, rural, and working-class.</li>
          <li>Please don’t hesitate to get in touch if you have any issues with your submission. You can reach us at <a class="guideline-link" href="mailto:wetgrainpoetry@pm.me">wetgrainpoetry@pm.me</a></li>
        </ul>
      </p><br>
      <p>*if you would like to make an anonymous submission please substitute your name for ‘anon’ or use initials.</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Guidelines',
}
</script>

<style>
  div.bar {
    height: 3px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 40%;
  }
  ul {
    list-style-type: square;
    margin-left: 20px;
  }
  div.card {
    background-color: #191308 !important;
  }
  .dialogTitle {
    text-align: center;
  }
  .dialogTitle, .dialogText p {
    color: white;
    font-size: 1.4em;
    word-spacing: .1em;
  }
  .guideline-link {
    color: white;
  }
  li {
    margin: 1em 0;
  }
</style>
