<template>
  <v-container>
    <v-row>
      <h1 class="section-header hidden" v-if="!$vuetify.breakpoint.mobile">lel</h1>
    </v-row>
    <v-row>
      <div v-if="poetData.url" class="yt">
        <iframe 
          width="100%" 
          height="100%" 
          :src="`https://www.youtube.com/embed/${poetData.url}`"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
      <div v-else class="yt-placeholder">
        <span>
          no YouTube link yet
        </span>
      </div>
    </v-row>
    <v-row class="text-center">
      <span v-for="poem, i in poetData.poems" :key="poem.title">
        <router-link :to="{ name: 'PoemDialog', params: { poem: poem.title }}" class="poem-link">{{ poem.title.toUpperCase() }}</router-link>
        <span v-if="i < poetData.poems.length - 1">&emsp;</span>
      </span>
    </v-row>
    <router-view />
  </v-container>
</template>
<script>
import poets from '@/assets/poets.js';

export default {
  name: 'Poet',
  computed: {
    poetData() {
      return poets.filter((poet) => poet.name === this.$route.params.poet)[0];
    },
  },
};
</script>
<style>
  .poem-link {
    color: #007296;
  }
  .yt, .yt-placeholder {
    width: 100%;
    padding-top: 56.25%;
    position: relative

  }
  .yt-placeholder {
    border: black solid 3px;
    box-sizing: border-box;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .yt iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .hidden {
    visibility: hidden;
  }
</style>

