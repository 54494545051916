<template>
  <v-dialog 
    v-model="$route.params.poem"
    @click:outside="$router.push({ name: 'Poet', params: { poet: $route.params.poet }})"
    class="dialog"
  >
    <v-card color="#7f6000" class="poem-font" dark min-width="300px">
      <v-card-title>{{ poem.title }}</v-card-title>
      <v-card-text>
        <pre v-html="poem.text" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import poets from '@/assets/poets.js';

export default {
  name: 'PoemDialog',
  computed: {
    poem() {
      const ret = poets
        .filter((poet) => poet.name === this.$route.params.poet)[0].poems
        .filter((poem) => poem.title === this.$route.params.poem)[0];
      ret.text = ret.text.replace(/ {2,}/g, '');
      return ret;
    },
  },
  mounted() {
    console.log(this.poem);
  }
};
</script>
<style>
.poem-font {
  font-family: 'minion pro';
}
.v-dialog {
  width: unset !important;
  background-color: #7f6000;
}
</style>
