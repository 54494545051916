<template>
  <v-container>
    <v-row v-if="!$vuetify.breakpoint.mobile">
      <div class="top-spacer" />
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-row>
          <h1 class="section-header">archive</h1>
        </v-row>
        <v-row v-if="!$route.params.poet">
          <v-col v-for="poet in archive" :key="poet.name" cols="6" md="4">
            <router-link :to="{ name: 'Poet', params: { poet: poet.name }}">
              <v-img aspect-ratio="0.7894" class="poet greyscale" :src="poet.img" />
            </router-link>
          </v-col>
        </v-row>
        <template v-else>
          <v-row>
            <p class="poet-desc">
              <v-img aspect-ratio="0.7894" class="float-left poet margin-right" width="200" :src="currentPoet.img" />
              <b>{{ currentPoet.fullName }}</b>
              <br />
              <span v-html="currentPoet.desc" />
            </p>
          </v-row>
          <v-row>
            <p class="poet-desc">
              <router-link class="back" :to="{ name: 'Archive'}"><v-icon>mdi-chevron-left</v-icon> go back</router-link>
            </p>
          </v-row>
        </template>
      </v-col>
      <v-col cols="12" md="8" class="about">
        <router-view v-if="$route.params.poet" />
        <v-img 
          v-else-if="!$vuetify.breakpoint.mobile"
          class="img"
          src="@/assets/title.png"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import poets from '@/assets/poets.js';

export default {
  name: 'Archive',
  computed: {
    archive: () => poets,
    currentPoet() {
      return poets.filter((poet) => poet.name === this.$route.params.poet)[0];
    },
  },
};
</script>
<style>
i.v-icon {
  padding: 0;
}
.poet {
  border: solid 2px black;
  border-radius: 10px;
  width: 100%;
}
.back {
  text-decoration: none;
  border-bottom: rgba(0, 0, 0, .6) 2px solid;
}
.greyscale {
  filter: grayscale(100%);
  transition: ease-out .5s;
}
.greyscale:hover, .greyscale:active {
  filter: grayscale(0);
}
.margin-right {
  margin-right: 20px;
}
.poet-desc {
  font-size: 1.5em;
}
.portrait {
  float: left;
  width: 30%;
}
.img div {
  background-size: unset;
}
</style>
