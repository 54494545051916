export default [
  {
    name: 'angus',
    fullName: 'Angus Reid',
    img: require('@/assets/angus.png'),
    url: 'FFhhTLhSNSI',
    desc: 'Angus Reid is a Scottish artist, writer and film-maker. His plays include How to Kill (1989) and Believer (1992); his films include Brotherly Love (1994), The Ring (2002) and Primary School Musical! (2012); his poetry includes The Book of Days (2012) and The History of Art in 100 Limericks (2018). Recent exhibitions include 6 Peaks (2012), Vision of Nicosia (2015) and Parallel Lives (forthcoming, Summerhall).',
    poems: [
      {
        title: 'Feeling',
        text: `In the late blue of summer I’ll go off on my own
          Paths of prickly wheat and low-grown grass to tread
          And dreaming, I’ll feel the freshness at my feet
          I’ll leave it to the wind to bathe my naked head

          I’ll have nothing to say, no thought going on
          But in my soul infinite love will have risen
          And I’ll go on like Nature’s vagabond
          Just happy, like with a woman


          for Douglas`
      },
      {
        title: 'Sensation',
        text: `Par les soirs bleus d’été, j’irai dans les sentiers
          Picoté par les blés, fouler l’herbe menue
          Rêveur, j’en sentirai la fraîcheur à mes pieds
          Je laisserai le vent baigner ma tête nue.

          Je ne parlerai pas, je ne penserai rien
          Moi l’amour infini me montera dans l’âme
          Et j’irai loin, bien loin, comme un bohémien
          Par la Nature, heureux comme avec une femme.


          Arthur Rimbaud`
      }
    ]
  },
  {
    name: 'elle',
    fullName: 'Elle Heedles',
    img: require('@/assets/elle.png'),
    url: '4sHGkQGLQNg',
    desc: 'Born in Vienna, Austria to American parents, they received a BA in English Literature from the University of Edinburgh. Their poems have appeared in <i>Pain</i>, <i>The Inkwell</i>, <i>adjacent pineapple</i>, and others, and are forthcoming in the <i>Northwest Review</i>. They are currently based in Vienna.',
    poems: [
      {
        title: 'White Noise',
        text: `There’s a western on somewhere
          and the work is easy. It makes me

          an indoor person. I think the coffee
          with new water and doubt myself.

          All at once a business of horses
          hold their hooves over my mouth

          so the voice in my head barely manages
          a sideward glance, a false gossip,

          like who’s going to read this
          and weep. Out of nowhere

          a thousand pages of gunfire—
          who do I know that loves

          violence in the afternoon?
          I hear the credits rolling,

          the song that accompanies sun
          on it’s way to something better.

          shortly after, the tapes switch
          and the sun makes its gallant return.`
      },
      {
        title: 'Canal',
        text: `The kitchen is full of tenderness and dutch ovens,
          but I do not want to look for the ladder.

          The water is so blue, the bluest
          a green river can be. You cannot see

          from your half of the rising river
          but my nipples show through my shirt,

          and they are magnificent. I look up
          the dream meanings of canals

          but not floating in them, though
          I wish I could say I’ve done it.

          Just like the mirror game, though
          For walkie talkies. It is good,

          the connection. Sometimes a truck driver,
          but usually loud and bright. We exist

          like this for a while beside the water
          like two thank-you bags in a zephyr.

          There is a storm on the transmission.
          I climb the ladder to put the vessels where they belong.`
      },
      {
        title: 'Last Summer',
        text: `Forget the shade, the cold breeze
          coming from there,
          that way. A train

          pulls into the warmth,
          everyone’s first love at once
          in one foreign tongue.

          The men lay in the grass
          with their scripts
          and loose clothes.

          The trees keep the light
          from their eyes, reading
          their lines as men.

          The man of the villa
          catches a fish,
          and I have nothing.

          I have never read anything.
          I hold onto the railing
          upon alighting from the train,

          and a handful of candied lemons
          in my dress pocket
          pull themselves together.`
      },
      {
        title: 'Good Grief',
        text: `A dog dies
          down below,
          so sweet this
          morning, just

          this morning.
          The months
          crying afterward
          belong to the sun.

          There is traction
          after losing
          what’s close.
          The sun knows

          traction is
          being unable
          to let yourself
          disappear.`
      },
      {
        title: 'Graft',
        text: `Lonesome is the cowboy inside
          my throat, trying rope.

          The back of the throat is a prairie.

          Sadness is the cow I named Annie.

          I call her but she won’t come.

          The rope loosens.

          Do you want a treat?

          Tightens as it is thrown.

          The owner’s manual is long,
          the letters smaller than hooves.

          A mother clenches her throat –
          choking on a mother –

          the skin seared
          pink as long night.

          In every chapter there’s a whip
          and a dead man.

          The surgery will be costly,
          the earth’s surface is a graft

          to sew onto empty space.
          On every page of dust

          the roadrunner drifts passed,

          for whom the end has already been.`
      }
    ]
  },
  {
    name: 'anthony',
    fullName: 'Anthony Daly',
    img: require('@/assets/anthony.png'),
    url: 'PaPsjoxBabs',
    desc: 'Anthony Daly is a poet whose work is slanted imagist. His work has previously been published in RAUM, adjacent pineapple, and From Glasgow to Saturn, and he has an upcoming collaborative pamphlet being released again by adjacent pineapple.',
    poems: [
      {
        title: '-erie-',
        text: `plasmic clouds
          curdle

          photic skyyolk
          yellow

          rivermirrors
          honey-locusts

          catching fire
          -smoke in the trees

          burning coyote
          bones and duck

          -fat skinned
          oranges sawn

          -off shotgun
          rings metallic

          hydroelectric hudson
          bullfrog

          rhythms score a
          dogeared noonday

          -dream phased
          moment`
      },
      {
        title: '-before, between, and after a drink-',
        text: `plum blossom
          noon

          sky cracks
          electric

          dead fruit
          flies a bird

          on the
          sun

          kokopelli flute
          hymns

          eye swim
          aked

          with a
          saint who

          holds dead
          flowers`
      },
      {
        title: '-adreamandnow-',
        text: `adirondack wood
          -crack heat

          synthesised june
          -pine whispers

          algonquin
          berceuse

          mountain
          memories

          of milkskin
          mornings

          wilddogs howling
          a brighteye child

          carving jackknife
          melodies

          deep in river
          reeds sings

          a lonesome
          afternoon cowboy

          to a northwoods
          roadkill deerhunter

          spearfishing cherries
          to pass the time`
      }
    ]
  },
  {
    name: 'kirsty',
    fullName: 'Kirsty Dunlop',
    img: require('@/assets/kirsty.png'),
    url: 'yB2BhOVGKb0',
    desc: 'Kirsty Dunlop lives in Glasgow and writes poems, short stories, electronic literature and collaborative work. She is a DFA candidate in Creative Writing at the University of Glasgow and is the poetry and non-fiction editor at SPAM Press. Most recent work is a broadside collaboration with the poet nicky melville, THE FACT THAT, forthcoming from <a href="https://gongfarm.cargo.site" target="_blank" rel="noopener noreferrer">GONG FARM</a>.',
    poems: [
      {
        title: 'I wanted so much just to be',
        text: `The foam gently mapping.
          The safer swim.
          The other deck, the dusk of.
          My permanent desires.
          Not a crash but another simmer on your surface.
          Over there, a following of information.
          A buffering of air.
          Ahead of breath by miles.
          Old time moving over here through the ripple of a word.
          Your threaded thoughts.
          Hearing all that:
          the whoosh back
          the halt forward.`
      }
    ]
  },
  {
    name: 'nasim',
    fullName: 'Nasim Luczaj',
    img: require('@/assets/nasim.png'),
    url: 'vMClgvzcHdg',
    desc: 'Nasim Luczaj is a Glasgow-based poet, DJ, translator and life model. She is the author of HIND MOUTH, a pamphlet for the Earthbound Poetry Series. Many of her poems live scattered under such online rocks as BlueHouse, Tentacular, DATABLEED, Adjacent Pineapple, SPAM or ‘Pider Mag’.',
    poems: [
      {
        title: 'Break',
        text: `the dark peruses
          itself without motive
          i’m rested like a colour
          observe every tremor
          of a poem as it falls
          off the roof
          and breaks its spine
          meanwhile wings clutter
          our cupboard like daffodils
          the hill or angst the insides
          and our windows stutter at bay
          a sea flexing and flexing us gone`
      },
      {
        title: 'Say',
        text: `the sea has finally wilted in our arms.
          our muscles, used to the waves, begin to tug
          at something more vital. the seagulls
          punctuate their usual way. the weeds
          curl, deranged, inland. our bones hassle us,
          the very notion of edge clambers out
          of retinas and starts to run without stopping
          an end is a shrug, sigh, bruise, or none
          of the above; the above collapsing.
          the hopeless case is loftier to build.
          we’d say the sea’s thirst, ignored
          then blaring, ever-unutterable, runs past
          the sea. it carries and we hear. we hear
          what we might need to. what we need
          to hear is what we hear in the first place.
          this viciousness, say, salt, deafens us on.`
      },
      {
        title: 'Ajar',
        text: `i think a flower has crept out
          of my body, outlived it.
          softness stitches that slit
          rolling moon down my shoulder.
          roll a little longer, i implore
          with the zeal of a water curtain
          blurted endlessly out of a rock.
          to relax: to be well-placed bloom.
          from here life as a whole
          might have been a stone
          trained to tell flesh where to go.
          mouths stagger above the bowl.
          in the end it can be comforting for fruit
          to replace fruit. hug me hug me
          the leashed light keeps barking.`
      },
    ],
  },
  {
    name: 'maria',
    fullName: 'Maria Sledmere',
    img: require('@/assets/maria.png'),
    url: '',
    desc: 'Maria Sledmere is an artist, poet, member of A+E Collective and editor-in-chief of SPAM Press. Publications include infra•structure – with Katy Lewis Hood (Broken Sleep), Chlorophyllia (OrangeApple Press) and neutral milky halo (Guillemot Press). With Rhian Williams, she recently co-edited the weird folds: everyday poems from the Anthropocene (Dostoyevsky Wannabe).',
    poems: [
      {
        title: 'Prairie Oyster Sonnet',
        text: `Quantities of nimbostratus aspartame
          you put in the punch. am I to be conditioned
          like your tresses on wednesday, fruition
          is a myth of the nymphs who go refresh
          first our life meter, then their leafiest
          dome in the desert is open aphorism
          for anyone — well let’s go statue to
          statue, pink and drunk revisionaries
          of tender proportion. call it philosophy
          or call it quits this close to Alaskan
          neuroplasticity of icicles
          we break to eat: I have to go softly
          when it comes to nourishment, the trashcan
          is all that I am inside nightingales`
      },
      {
        title: 'Evangeline',
        text: `If we heavenly already, in the outdoor café of carnivorous plants
          you plait my language headily
          &emsp;and of course the skylarks look like skylarks
          &emsp;&emsp;in disco elegia
          &emsp;&emsp;twice-cooked little biscuit
          falling all over the leafiest dancefloor of my life
          like to be with chilblain, childminding your nerves
          &emsp;to blush errory or crush on error
          &ensp;a million tousled lilies
          or waitresses with bells on bring us our coffee, black as this
          midday portlet of solar occlusion, or not
          &emsp;&emsp;kiss curl soft&emsp;applause of the snow
          &emsp;&emsp;&emsp;with appetite like
          &emsp;&emsp;&emsp;&nbsp;the english sundew
          &emsp;&emsp;&emsp;&ensp;at the end of dormancy
          &emsp;&emsp;&emsp;&nbsp;click here to enter
          &emsp;&emsp;&emsp;this communal laminae
          &emsp;&emsp;of a death smells like air after human rain
          &emsp;&emsp;&emsp;getting stuck to get stuck in
          &emsp;&emsp;&emsp;&ensp;or anything roughened by earth.`
      },
    ],
  },
  {
    name: 'scott',
    fullName: 'Scott Lilley',
    img: require('@/assets/scott.png'),
    url: 'apG76cjt_s4',
    desc: 'Scott Lilley is studying towards his Creative Writing MSt. His work can be read in  <i>WetGrain</i> and Black Bough’s <i>Deep Time 2</i> Anthology, he can be found on twitter <a href="https://twitter.com/ScottGLilley">@scottglilley</a>. Someday he’d like to grow some chillies.',
    poems: [
      {
        title: 'Fernworthy Reservoir',
        text: `These earthworms have developed a cheddar tint
          post lockdown, evolved a coat of powdered cheese,
          puffed up and shrunken, turned to pulp at the tease
          of bootdown, turning the tarmac to open grave. Squint
          just right, and this metallised plastic film bares hints
          of blue, becomes a Higgins boat in this lease
          of light.
          &emsp;&emsp;This is the moment I’ve captured to freeze;
          Instagrammed it, worms, for some attention stint;
          &emsp;&emsp;&emsp;&emsp;mistar_lewis: wotsat👀, bae, they crisps 😠
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;(my phone blitzing in vibration)
          &emsp;&emsp;&emsp;&emsp;mattfox98: Love a virgin’s quotes 🙃
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;scottglilley and calwhite_1998
          &emsp;&emsp;&emsp;&emsp;like this
          &emsp;&emsp;Back at the reservoir we’re men.
          Cutting the water of the pitchest depth,
          our stones rustle in their skim across the surface;
          my phone buzzes; back at the reservoir we’re men.`
      },
    ],
  },
]