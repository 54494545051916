<template>
  <v-app>
    <v-main class="main">
      <router-view />
    </v-main>
    <v-footer class="footer" fixed color="#7f6000">
      <nav-bar />
    </v-footer>
  </v-app>
</template>

<script>
import NavBar from '@/components/NavBar';
export default {
  name: 'App',
  components: { NavBar },
};
</script>  
<style>
@font-face {
  font-family: LunaSans;
  src: url('./assets/LunaSans-Regular.ttf');
}
/* .footer {
  backdrop-filter: blur(2px);
} */
.main {
  margin-bottom: 60px;
}
</style>
