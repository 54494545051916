<template lang="html">
  <v-container class="sent">
    <v-row v-if="!$vuetify.breakpoint.mobile">
      <div class="top-spacer" />
    </v-row>
    <v-row>
      <h2>thanks!</h2>
    </v-row>
    <v-row>
      Thank you for submitting to WetGrain, Issue 3!
    </v-row>
    <v-row>
      We aim to contact all poets by January 3<sup>rd</sup>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Sent',
}
</script>

<style lang="css" scoped>
.sent {
  font-weight: bold;
  color: black;
  padding-bottom: 50px;
  font-size: 1.5em;
}
</style>
