<template>
  <v-container>
    <v-row v-if="!$vuetify.breakpoint.mobile">
      <div class="top-spacer" />
    </v-row>
    <v-row>
      <h1 class="section-header">about</h1>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-row>
          <v-img 
            contain
            src="@/assets/crane.png"
          />
        </v-row>
      </v-col>
      <v-col cols="12" md="6" lg="4" class="about">
        <p class="text-justify">
          <strong>WetGrain</strong> is a journal for new poetry close to the marrow of its own bones. <strong>Patrick Romero McCafferty</strong> is a Mexican-Scottish poet based in Edinburgh and <strong>Christian Lemay</strong>, a literature graduate, is currently pursuing an LLB for publishing and is based in Edinburgh & Glasgow. Guest editors will be announced for forthcoming issues
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'About',
}
</script>
<style>
h1.section-header {
  font-size: 6rem;
}
.about {
  font-size: 1.5em;
}
.about b {
  font-size: 1.2em;
}
</style>