<template>
  <div>
    <v-container class="catch-all">
      <v-dialog v-model="dialog">
        <guidelines />
      </v-dialog>
      <v-row v-if="!$vuetify.breakpoint.mobile">
        <div class="top-spacer" />
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <img src="@/assets/title.png" alt="WetGrain">
          </v-row>
          <v-row>
            <v-col cols="12">
              <h2>Issue 3: Submission Portal</h2>
            </v-col>
          </v-row>
          <v-row class="guide">
            <v-col cols="12">
              <p>(See our <u style="cursor: pointer;" @click="dialog = true">guidelines</u> and upload your file below)</p>
            </v-col>
            <div class="guide"></div>
          </v-row>
          <form action="send" method="post" encType="multipart/form-data">
            <v-row>
              <v-col cols="4">
                <b>name:</b>
              </v-col>
              <v-col sm="6" cols="8" class="input">
                <p>
                  <v-text-field dense required outlined name="name" type="text" />
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <b>email:</b>
              </v-col>
              <v-col sm="6" cols="8" class="input">
                <p>
                  <v-text-field dense outlined required class="" name="email" type="text" />
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <p class="h2"><b>file:</b></p>
              </v-col>
              <v-col sm="6" cols="8" class="input">
                <v-file-input outlined dense required class="submit" accept=".doc, .docx, .odt, .rtf, .txt, .wpd, .pages" name="file" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <p>
                  <v-btn type="submit">Submit</v-btn>
                </p>
              </v-col>
              <v-col sm="6" cols="8">
                <p class="deadline text-right">
                  <b>deadline: 02.02.2022</b>
                </p>
              </v-col>
            </v-row>
          </form>
          <v-row>
            <v-img v-if="$vuetify.breakpoint.mobile" src="@/assets/nicepicture.png" contain max-height="85vmin" alt="Geiles Bild" />
          </v-row>
          <v-row>
            <h1 class="page-title"></h1>
            <div />
          </v-row>
          <v-row class="issues">
            <v-icon color="black">mdi-cart-plus</v-icon>&nbsp;<div id="cart1"></div>
          </v-row>
          <v-row class="issues">
            <v-icon color="black">mdi-cart-plus</v-icon>&nbsp;<div id="cart2"></div>
          </v-row>
          <v-row class="issues">
            <v-icon color="black">mdi-cart-plus</v-icon>&nbsp;<div id="cart3"></div>
          </v-row>
        </v-col>
        <v-col cols="8" v-if="!$vuetify.breakpoint.mobile">
          <v-img src="@/assets/nicepicture.png" contain max-height="70vmin" alt="Geiles Bild" />
        </v-col>
      </v-row>
    </v-container>
    <v-footer color="transparent">
      <v-container class="text-right">
        <v-row>
          <v-col class="custom-col">
            <a href="https://www.instagram.com/wetgrainpoetry">
              <img src="@/assets/insta.png" alt="Instagram">
            </a>
            <a href="https://www.facebook.com/WetGrain">
              <img src="@/assets/fb.png" alt="Facebook">
            </a>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="custom-col">
            <h2>
              <span v-if="!$vuetify.breakpoint.mobile">contact:</span> 
              <a class="text-decoration-none" href="mailto:contact: wetgrainpoetry@pm.me">
                wetgrainpoetry@pm.me
              </a>
            </h2>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import ShopifyBuy from 'shopify';
import Guidelines from './Guidelines';

export default {
  name: 'LandingPage',
  data: () => ({
    dialog: false,
  }),
  components: { Guidelines },
  mounted() {
    console.log('ShopifyBuy'. ShopifyBuy);


    const client = ShopifyBuy.buildClient({
      domain: 'wet-grain-poetry.myshopify.com',
      storefrontAccessToken: 'dacd1f6a505ca446198206b6978be024',
    });

    const ui = ShopifyBuy.UI.init(client);

    const cart = {
      styles: {
        button: {
          'color': 'white',
          'background-color': 'black',
          ':hover': {
            'background-color': 'black',
          },
          ":focus": {
            "background-color": "black"
          }
        }
      }
    };

    const styles = {
      button: {
        'color': 'rgba(0, 0, 0, .87)',
        'width': '5000px',
        'background-color': '#FFBE53',
        ':hover': {
          'background-color': '#FFBE53',
        },
        "text-align": "left",
        ":focus": {
          "background-color": '#FFBE53',
        },
        'padding': 0,
        'font-family': 'Calibri',
        'font-size': '2.1em',
        'font-weight': 'normal',
      },
      buttonWrapper: {
        'width': '5000px',
        'margin-top': '0px',
        'text-align': 'left',
        'display': 'inline-block'
      }
    };

    const toggle = {
      styles: {
        toggle: {
          'color': 'white',
          'background-color': 'black',
          ':hover': {
            'background-color': 'black',
          },
          ":focus": {
            "background-color": "black",
            'margin-right:': '10px',
          }
        }
      }
    }

    ui.createComponent('product', {
      id: 5939722682531,
      node: document.getElementById('cart1'),
      options: {
        product: {
          contents: {
            img: false,
            title: false,
            price: false,
          },
          styles,
          text: {
            button: 'issue i: autumn, 2020'
          }
        },
        cart,
        toggle,
      }
    });

    ui.createComponent('product', {
      id: 7247500345507,
      node: document.getElementById('cart2'),
      options: {
        product: {
          contents: {
            img: false,
            title: false,
            price: false,
          },
          styles,
          text: {
            button: 'issue ii: summer, 2021'
          }
        },
        cart,
        toggle,
      }
    });

    ui.createComponent('product', {
      id: 7346104008867,
      node: document.getElementById('cart3'),
      options: {
        product: {
          contents: {
            img: false,
            title: false,
            price: false,
          },
          styles,
          text: {
            button: 'issue i & ii (£10 + pp)'
          }
        },
        cart,
        toggle,
      }
    });
  },
};
</script>

<style>
a {
    color: rgb(0, 0, 0, .87);
  }
#cart1, #cart2 {
  max-width: 100vw !important;
}
.blackBorder {
  margin-top: 11px;
  border: 1px solid black;
  height: 1.9em;
}
.custom-col {
  padding: 0;
}
.catch-all {
  font-size: 1.5em;
  font-family: LunaSans;
}
.issues {
  font-size: 1.4em;
  width: 100%;
}
.top-spacer {
  height: 10vh;
}
div.row {
  padding: 0 10px;
}
i.v-icon {
  padding-bottom: 12px;
}
sup {
  line-height: inherit;
}
h1 + div {
  height: 4px;
  margin: auto;
  width: 90%;
  background-color: black;
  margin-bottom: 30px;
}
</style>