import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '@/components/Home';
import About from '@/components/About';
import Archive from '@/components/Archive';
import Poet from '@/components/Poet';
import PoemDialog from '@/components/PoemDialog';
import Sent from '@/components/Sent';

Vue.use(VueRouter);

const routes = [
  { 
    path: '/',
    component: Home,
    name: 'Home',
  },
  { 
    path: '/about/',
    component: About,
    name: 'About',
  },
  { 
    path: '/archive/',
    component: Archive,
    name: 'Archive',
    children: [
      { 
        path: ':poet/',
        component: Poet,
        name: 'Poet',
        children: [
          {
            path: ':poem',
            component: PoemDialog,
            name: 'PoemDialog',
          },
        ],
      },
    ],
  },
  {
    path: '/send',
    component: Sent,
  }
];

export default new VueRouter({
  mode: "history",
  routes,
});
